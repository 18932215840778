module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"slate","accessToken":"MC5YcUtaVXhFQUFDSUFZaC1t.77-9HFTvv73vv70G77-9TQTvv705K2JSSe-_ve-_ve-_vVTvv70-Zu-_vS0p77-977-977-9J--_ve-_vRo","path":"/preview","previews":true,"pages":[{"type":"Blog","match":"/blog/:uid","path":"/blog/","component":"/opt/build/repo/src/pages/blog/article.js"},{"type":"Doc","match":"/docs/:uid","path":"/docs/","component":"/opt/build/repo/src/pages/docs/article.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Slate","short_name":"Slate","start_url":"/","icon":"./static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"OQHBKDUX","whitelistHostnames":["heyslate.com"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
