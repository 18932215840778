exports.linkResolver = function linkResolver(doc) {
  // Route for blog
  if (doc.type === "blog") {
    return "/blog/" + doc.uid
  }
  // Route for docs
  if (doc.type === "doc") {
    return "/docs/" + doc.uid
  }
  // Homepage route fallback
  return "/"
}