import "./src/styles/app.css"

// prismic.io
const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver'); 
registerLinkResolver(linkResolver);

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://fast.wistia.com/embed/medias/sz39rpxd0t.jsonp")
    addScript("https://fast.wistia.com/assets/external/E-v1.js")
  }
}