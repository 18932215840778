// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/@prismicio/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-article-js": () => import("./../../../src/pages/blog/article.js" /* webpackChunkName: "component---src-pages-blog-article-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-day-rate-calculator-js": () => import("./../../../src/pages/day-rate-calculator.js" /* webpackChunkName: "component---src-pages-day-rate-calculator-js" */),
  "component---src-pages-docs-article-js": () => import("./../../../src/pages/docs/article.js" /* webpackChunkName: "component---src-pages-docs-article-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-forecasting-software-js": () => import("./../../../src/pages/forecasting-software.js" /* webpackChunkName: "component---src-pages-forecasting-software-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoicing-software-js": () => import("./../../../src/pages/invoicing-software.js" /* webpackChunkName: "component---src-pages-invoicing-software-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-forecast-js": () => import("./../../../src/pages/product/forecast.js" /* webpackChunkName: "component---src-pages-product-forecast-js" */),
  "component---src-pages-product-invoice-js": () => import("./../../../src/pages/product/invoice.js" /* webpackChunkName: "component---src-pages-product-invoice-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-more-js": () => import("./../../../src/pages/product/more.js" /* webpackChunkName: "component---src-pages-product-more-js" */),
  "component---src-pages-product-schedule-js": () => import("./../../../src/pages/product/schedule.js" /* webpackChunkName: "component---src-pages-product-schedule-js" */),
  "component---src-pages-scheduling-software-js": () => import("./../../../src/pages/scheduling-software.js" /* webpackChunkName: "component---src-pages-scheduling-software-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

